<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('promotion.title') }}</h2>
    </div>
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0 gap-2">
          <app-action-toolbar
            @onPrint="onPrint()"
            @onExportXlsx="onExportXlsx()"
            @onExportAllXlsx="onExportAllXlsx()"
            @onDownloadPdf="onDownloadPdf()"
            :printPermission="true"
            :exportPermission="true"
          ></app-action-toolbar>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table
            class="table table-report sm:mt-2"
            id="tablePrint"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center" id="ignore-1">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="col.name === 'action' ? 'ignore-2' : ''"
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr
                v-for="(row, index) in rows"
                :key="index"
                class="intro-x"
                :class="{ removedRow: row.isRemoved }"
              >
                <td class="w-40" id="ignore-2">
                  <div
                    class="text-gray-600 text-xs whitespace-nowrap mt-0.5 text-center"
                  >
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row.chef, 'code') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row.chef, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row.chef.kitchen, 'kitchenName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'startDate') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'endDate') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'daysCount') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterPrice(row.amount || 0) }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                  <div class="font-small text-xs whitespace-nowrap mt-1">
                    {{ presenterTime(row, 'createdAt') }}
                  </div>
                </td>
                <td class="table-report__action" id="ignore-2">
                  <div class="flex justify-center items-center gap-4">
                    <button
                      type="button"
                      class="text-theme-24 font-medium"
                      @click="showModal(row.id)"
                    >
                      <Tippy
                        tag="div"
                        class="flex items-center gap-1 whitespace-nowrap"
                        :content="i18n('common.delete')"
                      >
                        <Trash2Icon class="w-4 h-4" />
                        {{ i18n('common.delete') }}
                      </Tippy>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.promotion')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :hasNext="hasNext"
          :hasPrev="hasPrev"
          :loading="loading"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
          @page-change="goToPage"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="promotion" @delete="doDestroy(selectedId)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  mixins: [paginationMixin, presenterMixin, exportPrintMixin],
  components: {
    DeleteModal
  },
  data() {
    return {
      // data important for Mixins
      fetchOptions: {},
      tableRef: null,
      exportTableName: 'promotions',
      exportResourceName: 'promotion',
      excludedCols: [3],
      ignoreElements: ['ignore-1', 'ignore-2'],
      // component Data
      columns: [
        {
          name: 'chefCode',
          field: 'chefCode',
          label: 'iam.fields.chefCode',
          align: 'center'
        },
        {
          name: 'chefName',
          field: 'chefName',
          label: 'promotion.table.chefName',
          align: 'center'
        },
        {
          name: 'kitchenName',
          field: 'kitchenName',
          label: 'promotion.table.kitchenName',
          align: 'center'
        },
        {
          name: 'startDate',
          field: 'startDate',
          label: 'promotion.table.startDate',
          align: 'center'
        },
        {
          name: 'endDate',
          field: 'endDate',
          label: 'promotion.table.endDate',
          align: 'center'
        },
        {
          name: 'daysCount',
          field: 'daysCount',
          label: 'promotion.table.daysCount',
          align: 'center'
        },
        {
          name: 'price',
          field: 'price',
          label: 'promotion.table.price',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'promotion.table.createdAt',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'promotion/list/rows',
      hasNext: 'promotion/list/hasNext',
      hasPrev: 'promotion/list/hasPrev',
      pagination: 'promotion/list/pagination',
      listLoading: 'promotion/list/loading',
      exportLoading: 'exportStore/loading'
    }),
    loading() {
      return this.listLoading || this.exportLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    filterFields() {
      return [
        // {
        //   label: this.i18n('iam.fields.chefCode'),
        //   value: 'chef.code',
        //   type: 'text'
        // },
        // {
        //   label: this.i18n('promotion.table.chefName'),
        //   value: 'chef.fullName',
        //   type: 'text'
        // },
        // {
        //   label: this.i18n('iam.chefDetails.kitchen.title'),
        //   value: 'chef.kitchen.kitchenName',
        //   type: 'text',
        //   localize: true
        // },
        {
          label: this.i18n('promotion.table.startDate'),
          value: 'startDate',
          type: 'date-range'
        },
        {
          label: this.i18n('promotion.table.endDate'),
          value: 'endDate',
          type: 'date-range'
        },
        {
          label: this.i18n('promotion.table.price'),
          value: 'amount',
          type: 'number-range'
        },
        {
          label: this.i18n('promotion.table.createdAt'),
          value: 'createdAt',
          type: 'date-range'
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.promotion')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.promotion')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'promotion/list/doFetch',
      doDestroy: 'promotion/list/doDestroy',
      doFetchNextPage: 'promotion/list/doFetchNextPage',
      doFetchPreviousPage: 'promotion/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'promotion/list/doChangePaginationCurrentPage',
      doExport: 'exportStore/doExport',
      doChangePaginationPageSize: 'promotion/list/doChangePaginationPageSize'
    }),
    showModal(id) {
      this.selectedId = id
      cash('#delete-modal-preview').modal('show')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
